import type { Ref } from "vue";
import { computed, ref, watchEffect } from "vue";
import { useSchedulerStore } from "@/store/scheduler";
import type { SlotsResponse } from "~/types/api/Slots";
import type { Slot } from "~/types/Slot";

export function useSchedulerGroupSlots(serviceIds: number[] | undefined, userId?: number | undefined) {
  const schedulerStore = useSchedulerStore();
  const slots = ref<Slot[]>([]);
  const pending = ref(true);
  const failed = ref(false);

  watchEffect(() => {
    slots.value = [];
    pending.value = true;
    failed.value = false;

    if (serviceIds === undefined) {
      pending.value = false;
      failed.value = true;
      return;
    }

    const slotsPromises = serviceIds.reduce<Promise<Ref<SlotsResponse> | null>[]>((accumulator, id) => {
      accumulator.push(schedulerStore.getSlots({ service: id, user: userId }));

      return accumulator;
    }, []);

    Promise.all(slotsPromises)
      .then((values) => {
        slots.value = values
          .reduce<Slot[]>((accumulator, data) => {
            if (data !== null) {
              accumulator = accumulator.concat(data.value.slots);
            }

            return accumulator;
          }, [])
          .sort((a, b) => {
            if (a.date > b.date) return 1;
            if (a.date < b.date) return -1;

            return 0;
          });

        if (slots.value.length === 0) {
          failed.value = true;
        }
      })
      .finally(() => {
        pending.value = false;
      });
  });

  const nextFreeSlot = computed(() => {
    if (slots.value.length > 0) {
      return slots.value[0];
    }

    return null;
  });

  return {
    failed,
    nextFreeSlot,
    pending,
    slots,
  };
}
