<template>
  <p class="text-xs text-gray-400">
    <template v-if="!failed">
      <span class="mr-1">Nächster freier Termin</span>
      <span
        v-if="props.pending"
        aria-hidden="true"
        aria-label="Laden"
        class="mt-2 inline-block h-2 w-10 w-[130px] animate-pulse rounded-full bg-green-700" />
      <span v-else-if="nextFreeSlot" class="inline-block text-sm font-medium text-green-700">
        {{ formatNextSlot(nextFreeSlot) }}
      </span>
      <span v-else class="text-md text-start font-medium text-red-600"> Keine Termine verfügbar </span>
    </template>
    <template v-else>
      <span class="text-xs text-gray-400"> Nächsten freien Termin anzeigen </span>
    </template>
  </p>
</template>

<script setup lang="ts">
import type { Slot } from "~/types/Slot";
import { formatNextSlot } from "~/util/schedulerFormatting";

const props = withDefaults(
  defineProps<{
    nextFreeSlot: Slot | null;
    failed: boolean;
    pending: boolean;
  }>(),
  {
    failed: false,
    nextFreeSlot: null,
    pending: false,
  },
);
</script>

<style scoped></style>
